import { START, FAILURE, SUCCESS } from 'actionTypes/postfixes';
import { GET, UPDATE } from 'actionTypes/prefixes';
import { STREAMS, STREAM } from 'actionTypes/names';
import {
  getStreamsList,
  getSingleStream,
  createStream as APIcreateStream,
  updateStream as APIupdateStream,
  deleteStream as APIdeleteStream,
} from 'api';

function fetchStreamsSuccess(payload) {
  return {
    type: `${GET}${STREAMS}${SUCCESS}`,
    payload,
  };
}

function fetchSingleStreamSuccess(payload) {
  return {
    type: `${GET}${STREAM}${SUCCESS}`,
    payload,
  };
}

function fetchStreamsFailure() {
  return {
    type: `${GET}${STREAMS}${FAILURE}`,
  };
}

function fetchStreamsStart() {
  return {
    type: `${GET}${STREAMS}${START}`,
  };
}

function updateStreamStart() {
  return {
    type: `${UPDATE}${STREAM}${START}`,
  };
}

function updateStreamSuccess() {
  return {
    type: `${UPDATE}${STREAM}${SUCCESS}`,
  };
}

export function fetchStreams() {
  return async dispatch => {
    dispatch(fetchStreamsStart());

    try {
      const { data } = await getStreamsList();
      if (Array.isArray(data)) {
        dispatch(fetchStreamsSuccess(data));
      }
    } catch {
      dispatch(fetchStreamsFailure());
    }
  };
}

export function fetchSingleStream(streamId) {
  return async dispatch => {
    const { data, success } = await getSingleStream(streamId);

    if (success) {
      const {
        id,
        title,
        offerView,
        landingViews,
        layerViews,
        postBackOnRegistrationUrl,
        postBackOnConfirmLeadUrl,
        facebookPixelId,
        facebookPixelUrl,
      } = data;
      dispatch(
        fetchSingleStreamSuccess({
          id,
          title,
          offer: offerView.split('/')[offerView.split('/').length - 1],
          selectLandings: landingViews.length > 0 ? landingViews.map(_ => _.id) : [],
          selectLayers: layerViews.length > 0 ? layerViews.map(_ => _.id) : [],
          postBackOnRegistrationUrl,
          postBackOnConfirmLeadUrl,
          facebookPixelId,
          facebookPixelUrl,
        }),
      );
    }
  };
}

export function createStream({ params }, callback) {
  return async dispatch => {
    dispatch(updateStreamStart());

    const { success } = await APIcreateStream(params);

    if (success) {
      dispatch(updateStreamSuccess());
      callback();
    }
  };
}

export function updateStream({ id, params }, callback) {
  return async dispatch => {
    dispatch(updateStreamStart());

    const { success } = await APIupdateStream(id, params);
    if (success) {
      dispatch(updateStreamSuccess());
      callback();
    }
  };
}

export function deleteStream(id) {
  return async dispatch => {
    dispatch(updateStreamStart());

    const { status } = await APIdeleteStream(id);

    if (status === 204) dispatch(updateStreamSuccess());
  };
}
