import jwt_decode from 'jwt-decode';
import { logoutUser, setCurrentUser } from 'actions';
import { setCountries } from 'actions/config';
import { setUserToken } from 'services/userToken';
import store from 'store';

export const setAuth = () => {
  if (localStorage.jwtToken) {
    const decoded = jwt_decode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(decoded));
    setUserToken(localStorage.jwtToken);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      store.dispatch(logoutUser());
    } else {
      store.dispatch(setCountries());
    }
  }
};
