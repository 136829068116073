import axios from 'axios';

export async function getSingleStream(id) {
  try {
    const { data, status } = await axios.get(`/api/user/stream_views/${id}`);
    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
