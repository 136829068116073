import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ru, en } from './locales';
import { getCookie } from 'utils/cookie';

const currentLocaleCode = getCookie('lunetics_locale') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    ru,
    en,
  },
  ns: [
    'landing',
    'landing_content',
    'app',
    'offers',
    'streams',
    'statistics',
    'tickets',
    'profile',
    'finance',
    'profile',
    'service',
    'store',
    'errors',
  ],
  defaultNS: 'landing',
  lng: currentLocaleCode,
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
  },
});

export default i18n;
