import { START, FAILURE, SUCCESS } from 'actionTypes/postfixes';
import { GET, SET } from 'actionTypes/prefixes';
import { OFFERS, OFFERS_VIEW } from 'actionTypes/names';
import { getOffersList } from 'api';

function fetchOffersSuccess(payload) {
  return {
    type: `${GET}${OFFERS}${SUCCESS}`,
    payload,
  };
}

function fetchOffersFailure() {
  return {
    type: `${GET}${OFFERS}${FAILURE}`,
  };
}

function fetchOffersStart() {
  return {
    type: `${GET}${OFFERS}${START}`,
  };
}

export function setOffersView(payload) {
  return {
    type: `${SET}${OFFERS_VIEW}`,
    payload,
  };
}

export function fetchOffers(params) {
  return async (dispatch, getState) => {
    dispatch(fetchOffersStart());

    try {
      const { data, success } = await getOffersList(params);

      if (success) {
        const {
          offers: {
            data: { items },
          },
        } = getState();
        const list =
          params.page > 1 ? [].concat(items, data['hydra:member']) : data['hydra:member'];
        dispatch(
          fetchOffersSuccess({
            total: data['hydra:totalItems'],
            items: list,
          }),
        );
      }
    } catch (error) {
      dispatch(fetchOffersFailure());
      console.error(error);
    }
  };
}
