import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { VERTICALS } from 'actionTypes/names';
import { GET } from 'actionTypes/prefixes';

const initialState = {
  list: [],
  loading: false,
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${GET}${VERTICALS}${START}`:
      return { ...state, loading: true, loaded: false };
    case `${GET}${VERTICALS}${FAILURE}`:
      return { ...state, loading: false, loaded: false };
    case `${GET}${VERTICALS}${SUCCESS}`:
      return { ...state, list: action.payload, loading: false, loaded: true };
    default:
      return state;
  }
}
