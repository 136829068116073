import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './store';
import axios from 'axios';
import { ShargoEnterprise } from './App';
import { setAuth } from 'middleware';
import './i18n';
import * as serviceWorker from './serviceWorker';

smoothscroll.polyfill();

axios.defaults.baseURL = process.env.REACT_APP_API_ORIGIN;

setAuth();

ReactDOM.render(
  <Provider store={store}>
    <ShargoEnterprise />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
