import axios from 'axios';

export async function getStatisticsList(params) {
  try {
    const { data, status } = await axios.get('/api/user/statistics_views', {
      params,
      headers: {
        Accept: 'application/ld+json',
      },
    });
    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;
    return { data, status, success: false };
  }
}
