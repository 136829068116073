import axios from 'axios';

export async function deleteStream(id) {
  try {
    const { data, status } = await axios.delete(`/api/user/stream_views/${id}`);
    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
