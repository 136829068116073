import loadable from '@loadable/component';
import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Wrapper = loadable(() => import(/* webpackPreload: true */ 'components/App/Wrapper'));

export const PrivateRoute = ({ component: Component, path, exact }) => {
  const userData = useSelector(state => state.user);

  return (
    <Route path={path} exact={exact}>
      {userData.isAuthenticated ? (
        <Wrapper>
          <Component user={userData.user} />
        </Wrapper>
      ) : (
        <Redirect to="/" />
      )}
    </Route>
  );
};
