import { SET_ACTIVE_POPUP } from '../actions/types';

const initialState = {
  activePopup: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_POPUP:
      return {
        ...initialState,
        activePopup: action.payload,
      };
    default:
      return state;
  }
}
