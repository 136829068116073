import axios from 'axios';

export async function getVerticals() {
  try {
    const { data, status } = await axios.get('/api/user/vertical_views');
    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
