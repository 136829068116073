import axios from 'axios';

export async function getLandings(offerId) {
  try {
    const { data, status } = await axios.get(`/api/user/landing_views?offer=${offerId}`);
    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
