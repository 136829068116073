import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { PAYOUT, PAYOUT_LIST } from 'actionTypes/names';
import { CREATE, GET } from 'actionTypes/prefixes';

const initialState = {
  list: [],
  creating: false,
  loading: false,
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${CREATE}${PAYOUT}${START}`:
      return { ...state, creating: true };
    case `${CREATE}${PAYOUT}${FAILURE}`:
      return { ...state, creating: false };
    case `${GET}${PAYOUT_LIST}${START}`:
      return { ...state, loading: true, loaded: false };
    case `${GET}${PAYOUT_LIST}${SUCCESS}`:
      return { ...state, loading: false, loaded: true, list: action.payload };
    case `${CREATE}${PAYOUT}${SUCCESS}`:
    case `${GET}${PAYOUT_LIST}${FAILURE}`:
      return { ...state, creating: false, loaded: false, loading: false };
    default:
      return state;
  }
}
