import axios from 'axios';

export async function removeFavoriteOffer(offerId) {
  try {
    const { data, status } = await axios.delete(`/api/user/favorite_offer_views/${offerId}`);

    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
