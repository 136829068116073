import { START, FAILURE, SUCCESS } from 'actionTypes/postfixes';
import { GET } from 'actionTypes/prefixes';
import { LAYERS } from 'actionTypes/names';
import { getLayers } from 'api';

function fetchLayersSuccess(payload) {
  return {
    type: `${GET}${LAYERS}${SUCCESS}`,
    payload,
  };
}

function fetchLayersFailure() {
  return {
    type: `${GET}${LAYERS}${FAILURE}`,
  };
}

function fetchLayersStart() {
  return {
    type: `${GET}${LAYERS}${START}`,
  };
}

export function fetchLayers(offerId) {
  return async dispatch => {
    dispatch(fetchLayersStart());
    try {
      const { data } = await getLayers(offerId);

      dispatch(fetchLayersSuccess(data));
    } catch {
      dispatch(fetchLayersFailure());
    }
  };
}
