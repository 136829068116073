import axios from 'axios';

import { SET_COUNTRIES } from './types';

export const setCountries = () => dispatch => {
  axios
    .get(`/api/user/country_views`)
    .then(response => {
      dispatch({
        type: SET_COUNTRIES,
        payload: response.data,
      });
    })
    .catch(err => console.log(err));
};
