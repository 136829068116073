import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { STREAMS, STREAM } from 'actionTypes/names';
import { GET, UPDATE } from 'actionTypes/prefixes';

const initialState = {
  list: [],
  singleStream: null,
  loading: false,
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${GET}${STREAMS}${START}`:
      return { ...state, loading: true, loaded: false };
    case `${GET}${STREAMS}${FAILURE}`:
      return { ...state, loading: false, loaded: false };
    case `${GET}${STREAMS}${SUCCESS}`:
      return { ...state, list: action.payload, loading: false, loaded: true };
    case `${UPDATE}${STREAM}${START}`:
      return { ...state, loading: true };
    case `${UPDATE}${STREAM}${SUCCESS}`:
      return { ...state, loading: false, loaded: false };
    case `${GET}${STREAM}${SUCCESS}`:
      return { ...state, singleStream: action.payload };
    default:
      return state;
  }
}
