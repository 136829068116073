import { APP_NAVIGATION } from 'actionTypes/names';
import { SET } from 'actionTypes/prefixes';

const initialState = {
  title: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${SET}${APP_NAVIGATION}`:
      return { ...state, title: action.payload };
    default:
      return state;
  }
}
