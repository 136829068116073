import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const HomeWithAuth = ({ component: Component, pathForAuth = '/profile', ...rest }) => {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Redirect to={pathForAuth} /> : <Component {...props} />)}
    />
  );
};
