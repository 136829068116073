import { SET_CURRENT_LOCALE } from '../actions/types';
import { getCookie } from 'utils/cookie';

const currentLocaleCode = getCookie('lunetics_locale') || 'ru';

const initialState = {
  currentLocale: currentLocaleCode,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_LOCALE:
      return {
        currentLocale: action.payload,
      };
    default:
      return state;
  }
}
