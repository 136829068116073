import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { STATISTICS, TABLE_SORTING } from 'actionTypes/names';
import { GET, SET } from 'actionTypes/prefixes';

const initialState = {
  data: {
    total: 0,
    items: [],
  },
  sortingTitle: 'Дата',
  loading: false,
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${GET}${STATISTICS}${START}`:
      return { ...state, loading: true, loaded: false };
    case `${GET}${STATISTICS}${FAILURE}`:
      return { ...state, loading: false, loaded: false };
    case `${GET}${STATISTICS}${SUCCESS}`:
      return { ...state, data: { ...state.data, ...action.payload }, loading: false, loaded: true };
    case `${SET}${STATISTICS}${TABLE_SORTING}`:
      return { ...state, sortingTitle: action.payload };
    default:
      return state;
  }
}
