import { default as landing } from './landing.json';
import { default as landing_content } from './landing_content.json';
import { default as app } from './app.json';
import { default as offers } from './offers.json';
import { default as streams } from './streams.json';
import { default as service } from './service.json';
import { default as statistics } from './statistics.json';
import { default as finance } from './finance.json';
import { default as tickets } from './tickets.json';
import { default as profile } from './profile.json';
import { default as store } from './store.json';
import { default as errors } from './errors.json';

export const en = {
  landing,
  landing_content,
  app,
  offers,
  streams,
  statistics,
  finance,
  service,
  tickets,
  profile,
  store,
  errors,
};
