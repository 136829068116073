import { START, FAILURE } from 'actionTypes/postfixes';
import { REGISTER, AUTH } from 'actionTypes/names';
import { registerUser } from 'api';
import { loginRequest } from './login';

export function authFailure(payload) {
  return {
    type: `${AUTH}${FAILURE}`,
    payload,
  };
}

function registerRequestStart() {
  return {
    type: `${REGISTER}${START}`,
  };
}

export function registerRequest(payload) {
  return async dispatch => {
    dispatch(registerRequestStart());

    const { data, success } = await registerUser(payload);

    if (success) {
      return dispatch(loginRequest(payload));
    } else {
      dispatch(
        authFailure(
          data.violations.reduce((acc, error) => {
            acc[error.propertyPath] = error.message;
            return acc;
          }, {}),
        ),
      );
    }
  };
}
