import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { LANDINGS, APP_NAVIGATION } from 'actionTypes/names';
import { GET, SET } from 'actionTypes/prefixes';

const initialState = {
  list: [],
  loading: false,
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${GET}${LANDINGS}${START}`:
      return { ...state, loading: true, loaded: false };
    case `${GET}${LANDINGS}${FAILURE}`:
      return { ...state, loading: false, loaded: false };
    case `${GET}${LANDINGS}${SUCCESS}`:
      return { ...state, list: action.payload, loading: false, loaded: true };
    case `${SET}${APP_NAVIGATION}`:
      return initialState;
    default:
      return state;
  }
}
