import axios from 'axios';

export async function updateStream(id, params) {
  try {
    const { data, status } = await axios.put(`/api/user/stream_views/${id}`, params);
    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
