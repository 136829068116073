import { START, FAILURE, SUCCESS } from 'actionTypes/postfixes';
import { GET } from 'actionTypes/prefixes';
import { LANDINGS } from 'actionTypes/names';
import { getLandings } from 'api';

function fetchLandingsSuccess(payload) {
  return {
    type: `${GET}${LANDINGS}${SUCCESS}`,
    payload,
  };
}

function fetchLandingsFailure() {
  return {
    type: `${GET}${LANDINGS}${FAILURE}`,
  };
}

function fetchLandingsStart() {
  return {
    type: `${GET}${LANDINGS}${START}`,
  };
}

export function fetchLandings(offerId) {
  return async dispatch => {
    dispatch(fetchLandingsStart());
    try {
      const { data } = await getLandings(offerId);

      dispatch(fetchLandingsSuccess(data));
    } catch (error) {
      dispatch(fetchLandingsFailure());
    }
  };
}
