import { combineReducers } from 'redux';
import user from './user';
import authErrors from './auth/error';
import popupReducer from './popupReducer';
import configReducer from './configReducer';
import localeReducer from './localeReducer';
import landings from './landing';
import layers from './layers';
import offers from './offers';
import navigation from './navigation';
import statistics from './statistics';
import stream from './stream';
import verticals from './verticals';
import payout from './payout';

export default combineReducers({
  errors: authErrors,
  user,
  popups: popupReducer,
  configs: configReducer,
  locale: localeReducer,
  landings,
  layers,
  offers,
  navigation,
  statistics,
  stream,
  verticals,
  payout,
});
