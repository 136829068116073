import { SUCCESS, FAILURE, START } from 'actionTypes/postfixes';
import { PAYOUT, PAYOUT_LIST } from 'actionTypes/names';
import { CREATE, GET } from 'actionTypes/prefixes';
import { addNewPayout, getPayoutsList } from 'api';

function fetchCreatePayoutSuccess(payload) {
  return {
    type: `${CREATE}${PAYOUT}${SUCCESS}`,
    payload,
  };
}

function fetchCreatePayoutFailure() {
  return {
    type: `${CREATE}${PAYOUT}${FAILURE}`,
  };
}

function fetchCreatePayoutStart() {
  return {
    type: `${CREATE}${PAYOUT}${START}`,
  };
}

function fetchPayoutListSuccess(payload) {
  return {
    type: `${GET}${PAYOUT_LIST}${SUCCESS}`,
    payload,
  };
}

function fetchPayoutListFailure() {
  return {
    type: `${GET}${PAYOUT_LIST}${FAILURE}`,
  };
}

function fetchPayoutListStart() {
  return {
    type: `${GET}${PAYOUT_LIST}${START}`,
  };
}

export function fetchCreatePayout(payload, callback) {
  return async dispatch => {
    dispatch(fetchCreatePayoutStart());

    const { data, success } = await addNewPayout(payload);

    if (success) {
      dispatch(fetchCreatePayoutSuccess(data));
      callback();
    } else {
      dispatch(fetchCreatePayoutFailure());
    }
  };
}

export function fetchPayoutList(params) {
  return async dispatch => {
    dispatch(fetchPayoutListStart());
    try {
      const { data, success } = await getPayoutsList(params);

      if (success) {
        dispatch(fetchPayoutListSuccess(data));
      }
    } catch (error) {
      dispatch(fetchPayoutListFailure());
      console.error(error);
    }
  };
}
