import { FAILURE } from 'actionTypes/postfixes';
import { AUTH } from 'actionTypes/names';

export default function(state = {}, action) {
  switch (action.type) {
    case `${AUTH}${FAILURE}`:
      return action.payload;
    default:
      return state;
  }
}
