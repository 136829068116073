import axios from 'axios';

export async function addNewPayout(params) {
  try {
    const { data, status } = await axios.post('/api/user/payout_views', { ...params });

    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
