import axios from 'axios';

export async function addFavoriteOffer(offerId) {
  try {
    const { data, status } = await axios.post('/api/user/favorite_offer_views', {
      offerView: `/api/user/offer_views/${offerId}`,
    });
    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
