import axios from 'axios';

export async function getPayoutsList(params) {
  try {
    const { data, status } = await axios.get('/api/user/payout_views', { params });

    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
