import { SET } from 'actionTypes/prefixes';
import { USER, DATA, BALANCE, INFO, WALLETS } from 'actionTypes/names';
import { getUserBalance, getUserInfo, getUserWallets } from 'api';

export const setCurrentUser = payload => {
  return {
    type: `${SET}${USER}${DATA}`,
    payload,
  };
};

function setUserBalance(payload) {
  return {
    type: `${SET}${USER}${BALANCE}`,
    payload,
  };
}

function setUserInfo(payload) {
  return {
    type: `${SET}${USER}${INFO}`,
    payload,
  };
}

function setUserWallets(payload) {
  return {
    type: `${SET}${USER}${WALLETS}`,
    payload,
  };
}

export const fetchUserBalance = () => async dispatch => {
  try {
    const { data, success } = await getUserBalance();
    if (success) {
      dispatch(
        setUserBalance({
          currencyCode: data[0].currencyCode,
          amount: parseFloat(data[0].amount),
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserInfo = () => async dispatch => {
  try {
    const { data, success } = await getUserInfo();
    if (success) {
      dispatch(setUserInfo(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserWallets = () => async dispatch => {
  try {
    const { data, success } = await getUserWallets();

    if (success) {
      dispatch(setUserWallets(data));
    }
  } catch (error) {
    console.error(error);
  }
};
