import axios from 'axios';

export async function getUserInfo() {
  try {
    const { data, status } = await axios.get('/api/user/user_views/0');

    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
