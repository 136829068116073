import { START, FAILURE } from 'actionTypes/postfixes';
import { LOGIN_REQUEST, AUTH } from 'actionTypes/names';
import { loginUser } from 'api';
import { setUserToken } from 'services/userToken';
import { setCurrentUser } from './user';
import jwt_decode from 'jwt-decode';

export function authFailure(payload) {
  return {
    type: `${AUTH}${FAILURE}`,
    payload,
  };
}

function loginRequestStart() {
  return {
    type: `${LOGIN_REQUEST}${START}`,
  };
}

export function loginRequest(payload) {
  return async dispatch => {
    dispatch(loginRequestStart());

    const { data } = await loginUser(payload);

    if (data.token) {
      const { token } = data;
      localStorage.setItem('jwtToken', token);
      setUserToken(data.token);
      const decoded = jwt_decode(token);
      return dispatch(setCurrentUser(decoded));
    } else {
      dispatch(
        authFailure({
          message: data.message,
        }),
      );
    }
  };
}
