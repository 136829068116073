import axios from 'axios';

export async function loginUser(payload) {
  try {
    const { data, status } = await axios.post('/api/pub/login', payload);
    return { data, status };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status };
  }
}
