import './assets/styles/base.scss';

import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop';
import { useSelector } from 'react-redux';
import { PrivateRoute, HomeWithAuth } from 'middleware';

const Home = loadable(() => import(/* webpackPreload: true */ 'pages/home'));
const Profile = loadable(() => import(/* webpackPreload: true */ 'pages/profile'));
const Dashboard = loadable(() => import(/* webpackPrefetch: true */ 'pages/dashboard'));
const Finance = loadable(() => import(/* webpackPrefetch: true */ 'pages/finance'));
const OfferList = loadable(() => import(/* webpackPrefetch: true */ 'pages/offers/OfferList'));
const OfferPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/offers/OfferPage'));
const Support = loadable(() => import(/* webpackPrefetch: true */ 'pages/support'));
const StreamPage = loadable(() =>
  import(/* webpackPrefetch: true */ 'pages/streams/StreamPage/StreamPage'),
);
const StreamList = loadable(() => import(/* webpackPrefetch: true */ 'pages/streams/StreamList'));

const Rules = loadable(() => import('pages/rules'));
const PublicOffer = loadable(() => import('pages/public-offer'));
const Privacy = loadable(() => import('pages/privacy'));
const Terms = loadable(() => import('pages/terms'));
const CookiesPolicy = loadable(() => import('pages/cookies-policy'));

function NoMatch({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => {
        const {
          location: { pathname },
        } = routeProps;

        if (pathname === '/terms.html') {
          return <Redirect to="/terms" />;
        }

        if (pathname === '/privacy.html') {
          return <Redirect to="/privacy" />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
}

export function ShargoEnterprise() {
  const title = useSelector(state => state.navigation.title);

  useEffect(() => {
    document.title = title ? `${title} - Shargo Enterprise` : `Shargo Enterprise`;
  }, [title]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <HomeWithAuth path="/" exact component={Home} />
          <HomeWithAuth path="/privacy" component={Privacy} />
          <HomeWithAuth path="/terms" component={Terms} />
          <HomeWithAuth path="/public-offer" component={PublicOffer} />
          <HomeWithAuth path="/rules" component={Rules} />
          <HomeWithAuth path="/cookies-policy" component={CookiesPolicy} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/finance" component={Finance} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/offers/:id" component={OfferPage} />
          <PrivateRoute path="/offers" component={OfferList} />
          <PrivateRoute path="/support" component={Support} />
          <PrivateRoute path="/streams/create" component={StreamPage} />
          <PrivateRoute path="/streams/:id" component={StreamPage} />
          <PrivateRoute exact path="/streams" component={StreamList} />
          <NoMatch />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}
