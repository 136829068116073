export const LOAD = 'LOAD_';
export const FURTHER = 'FURTHER_';
export const UPDATE = 'UPDATE_';
export const RESET = 'RESET_';
export const SHOW = 'SHOW_';
export const HIDE = 'HIDE_';
export const GET = 'GET_';
export const SET = 'SET_';
export const CHANGE = 'CHANGE_';
export const VERIFY = 'VERIFY_';
export const CREATE = 'CREATE_';
export const REPLY = 'REPLY_';
export const MARK_AS_READ = 'TICKET_MARK_AS_READ_';
export const CLOSE = 'CLOSE_';
export const DELETE = 'DELETE_';
