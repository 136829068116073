import axios from 'axios';

export async function createStream(params) {
  try {
    const { data, status } = await axios.post('/api/user/stream_views', params);
    return { data, status, success: true };
  } catch (error) {
    console.error(error);
    const { data, status } = error.response;

    return { data, status, success: false };
  }
}
