import { SET, CREATE } from 'actionTypes/prefixes';
import { SUCCESS } from 'actionTypes/postfixes';
import { USER, DATA, BALANCE, INFO, APP_NAVIGATION, PAYOUT, WALLETS } from 'actionTypes/names';
import isEmpty from 'components/validation/is-empty';

const initialState = {
  isAuthenticated: false,
  user: {},
  balance: {
    currencyCode: 'USD',
    amount: 0.0,
    actual: false,
  },
  info: {
    id: 0,
    username: '',
    phoneNumber: null,
    skype: null,
    telegram: null,
    actual: false,
  },
  paymentSystems: {
    list: [],
    actual: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${SET}${USER}${DATA}`:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case `${SET}${USER}${BALANCE}`:
      return {
        ...state,
        balance: {
          ...action.payload,
          actual: true,
        },
      };
    case `${SET}${USER}${INFO}`:
      return {
        ...state,
        info: {
          ...action.payload,
          actual: true,
        },
      };
    case `${SET}${USER}${WALLETS}`:
      return { ...state, paymentSystems: { list: action.payload, actual: true } };
    case `${SET}${APP_NAVIGATION}`:
    case `${CREATE}${PAYOUT}${SUCCESS}`:
      return {
        ...state,
        balance: {
          ...state.balance,
          actual: false,
        },
      };
    default:
      return state;
  }
}
